<template>
	<el-dialog title="添加好友" :visible.sync="dialogVisible" width="40%" :before-close="onClose">
		<el-input placeholder="输入用户名或昵称进行,最多展示20条" class="input-with-select" v-model="searchText"
			@keyup.enter.native="onSearch()">
			<i class="el-icon-search el-input__icon" slot="suffix" @click="onSearch()"> </i>
		</el-input>
		<div style="display: flex;">
			<el-tree style="width: 30%;height:400px;overflow:scroll;" :data="tree" :props="defaultProps" empty-text="无部门数据" accordion
				@node-click="handleNodeClick">
			</el-tree>
			<el-scrollbar style="height:400px">
				<div v-for="(user) in users" :key="user.id" v-show="user.id != $store.state.userStore.userInfo.id">
					<div class="item">
						<div class="avatar">
							<head-image :name="user.nickName" :url="user.headImage" :online="user.online"></head-image>
						</div>
						<div class="add-friend-text">
							<div class="text-user-name">
								<div>{{ user.userName }}</div>
								<div :class="user.online ? 'online-status  online' : 'online-status'">{{ user.online ? "[在线]" : "[离线]"
									}}</div>
							</div>
							<div class="text-nick-name">
								<div>昵称:{{ user.nickName }}</div>
								<div style="margin-left: 5px;">部门:{{ user.deptName }}</div>
							</div>
						</div>
						<el-button type="success" size="mini" v-show="!isFriend(user.id)" @click="onAddFriend(user)">添加</el-button>
						<el-button type="info" size="mini" v-show="isFriend(user.id)" plain disabled>已添加</el-button>
					</div>
				</div>
			</el-scrollbar>
		</div>
	</el-dialog>
</template>

<script>
import HeadImage from '../common/HeadImage.vue'


export default {
	name: "addFriend",
	components: { HeadImage },
	data () {
		return {
			users: [],
			searchText: "",
			tree: [{
				label: '一级 1',
				children: [{
					label: '二级 1-1',
					children: [{
						label: '三级 1-1-1'
					}]
				}]
			}, {
				label: '一级 2',
				children: [{
					label: '二级 2-1',
					children: [{
						label: '三级 2-1-1'
					}]
				}, {
					label: '二级 2-2',
					children: [{
						label: '三级 2-2-1'
					}]
				}]
			}, {
				label: '一级 3',
				children: [{
					label: '二级 3-1',
					children: [{
						label: '三级 3-1-1'
					}]
				}, {
					label: '二级 3-2',
					children: [{
						label: '三级 3-2-1'
					}]
				}]
			}],
			defaultProps: {
				children: 'children',
				label: 'deptName'
			}
		}
	},
	props: {
		dialogVisible: {
			type: Boolean
		}
	},
	created () {
		this.getDeptTree()
	},
	methods: {

		getDeptTree () {
			this.$http({
				url: "/user/findDeptTree",
				method: "get",
			}).then((data) => {
				this.tree = this.listToTree(data);
				console.log('this.tree---', this.tree)
			})
		},
		handleNodeClick (data) {
			console.log('handleNodeClick----', data);
			this.$http({
				url: "user/findUserByDeptId",
				method: "post",
				params: {
					ancestors: data.ancestors
				}
			}).then((data) => {
				this.users = data;
				console.log('this.users---', this.users)
			})
		},

		listToTree (list) {
			const map = {};
			const roots = [];
			// 初始化所有节点并存入map
			for (const item of list) {
				map[item.deptId] = { ...item, children: [] };
			}
			// 构建树结构
			for (const item of list) {
				if (item.parentId === 0) {
					roots.push(map[item.deptId]);
				} else {
					map[item.parentId].children.push(map[item.deptId]);
				}
			}
			return roots;
		},

		onClose () {
			this.$emit("close");
		},
		onSearch () {
			this.$http({
				url: "/user/findByName",
				method: "get",
				params: {
					name: this.searchText
				}
			}).then((data) => {
				this.users = data;
				console.log('this.users---', this.users)
			})
		},
		onAddFriend (user) {
			this.$http({
				url: "/friend/add",
				method: "post",
				params: {
					friendId: user.id
				}
			}).then((data) => {
				this.$message.success("添加成功，对方已成为您的好友");
				let friend = {
					id: user.id,
					nickName: user.nickName,
					headImage: user.headImage,
					online: user.online
				}
				this.$store.commit("addFriend", friend);
			})
		},
		isFriend (userId) {
			let friends = this.$store.state.friendStore.friends;
			let friend = friends.find((f) => f.id == userId);
			return friend != undefined;
		},
	}
}
</script>

<style lang="scss">
.el-dialog {
	min-width: 400px;
}

.item {
	height: 65px;
	display: flex;
	position: relative;
	padding-left: 15px;
	align-items: center;
	padding-right: 25px;

	.add-friend-text {
		margin-left: 15px;
		flex: 3;
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		overflow: hidden;

		.text-user-name {
			display: flex;
			flex-direction: row;
			font-weight: 600;
			font-size: 16px;
			line-height: 25px;

			.online-status {
				font-size: 12px;
				font-weight: 600;

				&.online {
					color: #5fb878;
				}
			}
		}

		.text-nick-name {
			display: flex;
			flex-direction: row;
			font-size: 12px;
			line-height: 20px;
		}

	}
}
</style>
